import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

// Create the keyframes
const loader = keyframes`
	0% {
		transform: rotate(0deg);
	}

	25% {
		transform: rotate(180deg);
	}

	50% {
		transform: rotate(180deg);
	}

	75% {
		transform: rotate(360deg);
	}

	100% {
		transform: rotate(360deg);
	}
`;

const loaderInner = keyframes`
	0% {
		height: 0%;
	}

	25% {
		height: 0%;
	}

	50% {
		height: 100%;
	}

	75% {
		height: 100%;
	}

	100% {
		height: 0%;
	}
`;

const variantColor = ({ variant, theme }) => {
	return theme.loaders[variant].color;
};

const variantBg = ({ variant, theme }) => {
	return theme.loaders[variant].bg;
};

/**
 * Loading component that displays a loading animation
 */

const Wrapper = styled(Box)`
	position: relative;
	text-align: center;
	width: 100%;
	height: 100%;
	background-color: ${variantBg};
`;

const LoadingStyled = styled.span`
	display: inline-block;
	position: relative;
	border: 4px solid ${variantColor};
	top: ${({ size }) => `calc(50% - ${size / 2}px);`};
	animation: ${loader} 2s infinite ease;
	height: ${({ size }) => size + 'px'};
	width: ${({ size }) => size + 'px'};
`;
const LoadingInnerStyled = styled.span`
	vertical-align: top;
	display: inline-block;
	width: 100%;
	background-color: ${variantColor};
	animation: ${loaderInner} 2s infinite ease-in;
`;

const Loader = (props) => (
	<Wrapper {...props}>
		<LoadingStyled {...props}>
			<LoadingInnerStyled {...props} />
		</LoadingStyled>
	</Wrapper>
);

Loader.propTypes = {
	/**
	 * Adds the loading element width and height.
	 */
	size: PropTypes.number,

	/**
	 * Adds a variant color based on variants defined in theme.loaders
	 */
	variant: PropTypes.string,
};

Loader.defaultProps = {
	variant: 'dark',
	size: 20,
};

/**
 * @component
 */

export default Loader;
