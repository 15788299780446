import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image, Link } from 'theme-ui';
import NextLink from 'next/link';
import Container from '../../components/Container';
import Button from '../../components/Button';
import { LOGIN_ROUTE, SIGN_UP_ROUTE } from '../../constants/routes';

const Header = ({
	logoHref,
	logoSrc,
	logoAlt,
	navLinks,
	loginLinkText,
	loginLink,
	signupCtaText,
	signupCtaLink,
	showLinks,
	showLogin,
	showSignUp,
	loginButton,
}) => {
	return (
		<Box
			as={'header'}
			py={[3, 3, 4]}
			color="black"
			bg="white"
			sx={{
				border: (theme) => `0 solid ${theme.colors.gray100}`,
				borderBottomWidth: 1,
			}}>
			<Container>
				<Flex as="nav" alignItems="center" justifyItems="space-between">
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}>
						<NextLink href={logoHref} passHref prefetch={false}>
							<Link>
								<Image src={logoSrc} alt={logoAlt} rel="image" />
							</Link>
						</NextLink>
					</Box>
					<Box
						mx="auto"
						alignItems="center"
						sx={{
							display: ['none', null, null, 'flex'],
						}}>
						{showLinks &&
							navLinks.map(({ linkText, linkPath }, idx) => (
								<NextLink key={idx} href={linkPath} passHref prefetch={false}>
									<Link variant="nav">{linkText}</Link>
								</NextLink>
							))}
					</Box>
					<Box ml={['auto', 'auto', 'auto', 0]}>
						{showLogin && (
							<NextLink href={loginLink} passHref>
								{loginButton ? (
									<Button size="small" outlined>
										{loginLinkText}
									</Button>
								) : (
									<Link variant="nav">{loginLinkText}</Link>
								)}
							</NextLink>
						)}
						{showSignUp && (
							<NextLink href={signupCtaLink}>
								<Button size="small" outlined>
									{signupCtaText}
								</Button>
							</NextLink>
						)}
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

Header.propTypes = {
	logoHref: PropTypes.string,
	logoSrc: PropTypes.string,
	logoAlt: PropTypes.string,
	navLinks: PropTypes.arrayOf(
		PropTypes.shape({
			linkText: PropTypes.string,
			linkPath: PropTypes.string,
		})
	),
	loginLinkText: PropTypes.string,
	loginLink: PropTypes.string,
	signupCtaText: PropTypes.string,
	signupCtaLink: PropTypes.string,
	showLinks: PropTypes.bool,
	showLogin: PropTypes.bool,
	loginButton: PropTypes.bool,
	showSignUp: PropTypes.bool,
};

Header.defaultProps = {
	logoHref: '/',
	logoSrc: '/images/togglez_logo_40.svg',
	logoAlt: 'Togglez Logo',
	navLinks: [
		{
			linkText: 'What is it',
			linkPath: '#what-is-it',
		},
		{
			linkText: 'How it works',
			linkPath: '#how-it-works',
		},
		{
			linkText: 'Pricing',
			linkPath: '#pricing',
		},
	],
	loginLinkText: 'Login',
	loginLink: LOGIN_ROUTE,
	signupCtaText: 'Get Started',
	signupCtaLink: SIGN_UP_ROUTE,
	showLinks: true,
	showLogin: true,
	showSignUp: true,
	loginButton: false,
};

Header.displayName = 'Header';

export default Header;
