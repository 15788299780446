import React from 'react';
import { Box } from 'theme-ui';

const Container = ({ size, ...props }) => (
	<Box
		mx="auto"
		px={[5, 5, 5, 5, 0]}
		sx={{
			maxWidth: (theme) => theme.breakpoints[size],
		}}
		{...props}
	/>
);

Container.defaultProps = {
	// Defaults to 1200px: 'xl' breakpoint
	size: 'xl',
};

Container.displayName = 'Container';

export default Container;
