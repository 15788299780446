import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button as ThemedButton, useThemeUI } from 'theme-ui';
import Loader from '../Loader';

const LoadingStyle = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
`;

const Button = React.forwardRef(
	(
		{ size, outlined, variant, rounded, disabled, loading, fullWidth, children, ...props },
		ref
	) => {
		const { theme } = useThemeUI();
		const outlineStyles = outlined ? theme.buttonOutline[variant] : {};
		const roundedStyles = rounded ? { borderRadius: '999px' } : {};
		const fullWidthStyles = fullWidth ? { width: '100%' } : {};
		const disabledStyles = disabled
			? {
					color: 'primary',
					bg: 'muted',
					cursor: 'no-drop',
			  }
			: {};
		const loader = (
			<LoadingStyle>
				<Loader variant={outlined ? 'dark' : 'light'} />
			</LoadingStyle>
		);
		return (
			<ThemedButton
				sx={{
					...theme.buttonSizes[size],
					...outlineStyles,
					...roundedStyles,
					...fullWidthStyles,
					...disabledStyles,
				}}
				variant={variant}
				ref={ref}
				{...props}>
				{children}
				{loading && loader}
			</ThemedButton>
		);
	}
);

Button.displayName = 'Button';

Button.propTypes = {
	size: PropTypes.string,
	outlined: PropTypes.bool,
	variant: PropTypes.string,
	rounded: PropTypes.bool,
	fullWidth: PropTypes.bool,
};

Button.defaultProps = {
	size: 'default',
	variant: 'primary',
};

export default Button;
