/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

const BaseLayout = ({ header, footer, children }) => (
	<>
		<a href="#mainContent" className="sr-only">
			Skip to main content
		</a>
		{header && header}
		<main id="mainContent">{children}</main>
		{footer && footer}
	</>
);

BaseLayout.prototypes = {
	header: PropTypes.node,
	footer: PropTypes.node,
	children: PropTypes.arrayOf(PropTypes.node),
};
BaseLayout.displayName = 'BaseLayout';

export default BaseLayout;
